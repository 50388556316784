<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <b-link
          variant="primary"
          @click="() => $router.back()"
        >
          <feather-icon
            icon="ChevronLeftIcon"
            class="mr-50"
          />
          Voltar
        </b-link>
      </b-col>
      <b-col
        cols="8"
        offset="2"
        class="text-center my-2"
      >
        <h4>Antecipe sua renovação automática com o plano atual ou altere seu plano mensal</h4>
        <p> Selecione a quantidade de leads que gostaria de renovar mensal, ou apenas mantenha a quantidade já estabelecida e efetue a renovação. Ao clicar em renovar iremos fazer a cobrança correspondente, para que você não fique sem suas indicações 🙂</p>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="8"
        offset="2"
      >
        <b-card>
          <b-row>
            <b-col
              cols="4"
              class="d-flex align-items-center flex-column px-3 py-1"
            >
              <h5>Leads de indicação</h5>
              <b-form-spinbutton
                v-model="indications"
                variant="primary"
                class="mt-2 spinbutton-primary"
                min="1"
                max="999"
              />
            </b-col>
            <b-col
              cols="4"
              class="d-flex align-items-center flex-column py-1"
            >
              <h5>Valor por indicação</h5>
              <h4
                class="mt-2"
              >{{ new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(getActualPricePerIndication.price) }}</h4>
            </b-col>
            <b-col
              cols="4"
              class="box-total py-1 border-separate"
            >
              <h5>Total da compra</h5>
              <template v-if="getActualPricePerIndication.actualRange > 0">
                <small>de <strike>
                  {{ new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(getTotalPrice.oldPrice) }}
                </strike> por</small>

              </template>

              <strong>
                {{ new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL'
                }).format(getTotalPrice.newPrice) }}
              </strong>
            </b-col>

            <b-col
              cols="12"
              class="p-1"
            >
              <vue-slider
                v-model="indications"
                :min="20"
                :max="999"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="8"
        offset="2"
      >
        <b-overlay
          :show="isAPICallInProgress"
          rounded="sm"
        >
          <b-card class="p-1">
            <b-row>
              <b-col
                cols="7"
                class="d-flex flex-row"
              >
                <div class="d-flex cardFlag">
                  <b-img
                    v-if="listSavedCreditCards"
                    :src="listSavedCreditCards ? require(`@/assets/images/svg/${listSavedCreditCards[0].flag}.svg`) : ''"
                    :alt="listSavedCreditCards ? listSavedCreditCards[0].flag : 'bandeira do cartão'"
                  />
                  <b-img
                    v-else
                    :src="listSavedCreditCards ? require('@/assets/images/svg/generic.svg') : ''"
                    :alt="listSavedCreditCards ? listSavedCreditCards[0].flag : 'bandeira do cartão'"
                  />
                </div>
                <div class="d-flex flex-column">
                  <p class="m-0">
                    {{ listSavedCreditCards ? listSavedCreditCards[0].name : '' }}
                  </p>
                  <h5 class="m-0">
                    <strong>
                      {{ listSavedCreditCards ? listSavedCreditCards[0].number : '' }}
                    </strong>
                  </h5>
                </div>
              </b-col>
              <b-col
                cols="1"
                class="d-flex align-items-center justify-content-center"
              >
                <b-img
                  src="@/assets/images/raty/star-on-2.png"
                  alt="svg img"
                />
              </b-col>
              <b-col
                cols="4"
                class="pl-3 d-flex flex-column justify-content-center border-separate"
              >
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-primary"
                  @click="() => modalChangePaymentMethod = !modalChangePaymentMethod"
                >
                  <feather-icon
                    icon="RepeatIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Trocar</span>
                </b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <b-row
      class="mb-2"
    >
      <b-col
        cols="12"
        class="d-flex align-items-center justify-content-center"
      >
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="success"
          @click="() => updateCurrentPlan()"
        >
          RENOVAR
        </b-button>
      </b-col>
    </b-row>

    <!-- modal alterar cartão -->
    <b-modal
      ref="modal-change-payment-method"
      v-model="modalChangePaymentMethod"
      title="Alterar forma de pagamento"
      ok-title="Confirmar"
      cancel-title="Cancelar"
      @ok="changePaymentMethod"
    >
      <!-- LISTA DE CARTÕES -->
      <b-row
        class="cardsList mb-2"
      >
        <b-col cols="12">
          <validation-provider
            #default="{ errors }"
            name="cartão de crédito"
            rules="required"
          >
            <div class="mb-2">
              <h5>
                <strong>Escolha o cartão</strong>
              </h5>

              <b-overlay
                :show="isAPICallInProgress"
                rounded="sm"
              >
                <b-card
                  v-for="card in listSavedCreditCards"
                  :key="card.id"
                  class="box-credit-card d-flex flex-column justify-content-center"
                  :class="{'checked' : selectedCardId === card.id}"
                >
                  <b-form-radio
                    v-model="selectedCardId"
                    :name="card.id"
                    :value="card.id"
                  >
                    <b-row>
                      <b-col cols="2">
                        <b-img
                          v-if="card.flag"
                          :src="require(`@/assets/images/svg/${card.flag}.svg`)"
                          :alt="card.flag"
                          class="card_flag mx-1"
                        />
                        <b-img
                          v-else
                          :src="require('@/assets/images/svg/generic.svg')"
                          :alt="card.flag"
                          class="card_flag mx-1"
                        />
                      </b-col>

                      <b-col
                        cols="8"
                        class="pl-3 d-flex flex-column justify-content-center"
                      >
                        <p class="m-0">
                          {{ card.name }}
                        </p>
                        <p class="m-0">
                          <strong>
                            {{ card.number }}
                          </strong>
                        </p>
                      </b-col>
                      <b-col
                        v-if="defaultPaymentMethodId === card.id"
                        cols="2"
                        class="d-flex align-items-center justify-content-center"
                      >
                        <b-img
                          src="@/assets/images/raty/star-on-2.png"
                          alt="svg img"
                        />
                      </b-col>
                    </b-row>
                  </b-form-radio>
                </b-card>
              </b-overlay>
              <small class="text-danger">{{ errors[0] }}</small>
            </div>
          </validation-provider>
        </b-col>
      </b-row>
      <!-- FIM DA LISTA DE CARTÕES -->
      <b-row>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.modal-add-new-card
            size="sm"
            variant="primary"
          >
            Adicionar novo cartão
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- modal adicionar novo cartão -->
    <b-modal
      id="modal-add-new-card"
      ref="add-new-card-modal"
      size="lg"
      title="Adicionar cartão de crédito"
      hide-footer
    >
      <b-row>
        <b-col cols="12">
          <CreditCardNew
            :from-update-plan="true"
            @completed="closeModalAddNewCard"
          />
        </b-col>
      </b-row>
    </b-modal>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'

import {
  BRow, BCol, BFormSpinbutton, BCard, BImg, BOverlay, BButton, BModal, BFormRadio, VBModal, BLink,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

import { ValidationProvider, localize } from 'vee-validate'
import { required, integer } from '@validations'

import useJwt from '@/auth/jwt/useJwt'
import changePositionItemInArray from '@/utils/changePositionItemInArray'

import VueSlider from 'vue-slider-component'

import CreditCardNew from '../creditcard-new/creditCardNew.vue'

export default {
  name: 'UpdatePlan',
  components: {
    BRow,
    BCol,
    BLink,
    BFormSpinbutton,
    VueSlider,
    BCard,
    BImg,
    ValidationProvider,
    BOverlay,
    BButton,
    BModal,
    BFormRadio,
    CreditCardNew,
    // ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      creditCardNumber: '',
      cvcCode: '',
      validate: '',
      managerName: '',
      flag: 'generic',
      indications: 20,
      renew: true,
      ranges: null,
      modalChangePaymentMethod: false,
      defaultPaymentMethodId: '',
      selectedCardId: '',
      useNewCard: false,
      listSavedCreditCards: null,
      creditCardMask: {
        delimiters: [' '],
        blocks: [4, 4, 4, 4],
      },
      validateMask: {
        delimiters: ['/'],
        blocks: [2, 2],
        uppercase: true,
      },
      cvvMask: {
        blocks: [3],
      },
      required,
      integer,
    }
  },
  computed: {
    getActualPricePerIndication() {
      let actualRange = 0
      let price = 0

      if (this.ranges) {
        if (this.indications <= this.ranges[0].toEnd[1]) actualRange = 0
        if (this.indications >= this.ranges[1].toEnd[0] && this.indications <= this.ranges[1].toEnd[1]) actualRange = 1
        if (this.indications >= this.ranges[2].toEnd[0] && this.indications <= this.ranges[2].toEnd[1]) actualRange = 2
        if (this.indications >= this.ranges[3].toEnd[0] && this.indications <= this.ranges[3].toEnd[1]) actualRange = 3
        if (this.indications >= this.ranges[4].toEnd[0] && this.indications <= this.ranges[4].toEnd[1]) actualRange = 4

        price = this.ranges[actualRange].price
      }

      return { actualRange, price }
    },
    getTotalPrice() {
      const oldPrice = this.ranges ? this.indications * this.ranges[0].price : 0
      const newPrice = this.indications * this.getActualPricePerIndication.price

      return { oldPrice, newPrice }
    },
    getIdSelectPlan() {
      return this.ranges.filter(plan => plan.price === this.getActualPricePerIndication.price)[0].id
    },
    ...mapGetters({
      isAPICallInProgress: 'api/isAPICallInProgress',
    }),
  },
  watch: {
    creditCardNumber() {
      const flagByIugu = window.Iugu.utils.getBrandByCreditCardNumber(this.creditCardNumber)
      this.flag = flagByIugu || 'generic'
      return flagByIugu
    },
  },
  mounted() {
    localize('pt_BR')

    this.getRangesIndications()
    this.getActualPlan()
    this.getCompanyPaymentMethods()
  },
  methods: {
    getRangesIndications() {
      useJwt.getAllRangesIndications()
        .then(response => {
          const rangesByApi = response.data.result.filter(range => range.isActive === true)
          this.ranges = rangesByApi.sort((a, b) => ((a.price < b.price) ? 1 : -1))
        })
        .catch(error => error)
    },
    getActualPlan() {
      useJwt.getActivePlan()
        .then(response => {
          this.indications = response.data.result.nextPlan.originalIndications
        })
        .catch(error => error)
    },
    getCompanyPaymentMethods() {
      useJwt.checkCompanyPaymentMethods()
        .then(response => {
          const result = JSON.parse(response.data.result)

          this.defaultPaymentMethodId = result.default_payment_method_id

          const paymentListMethodsRaw = result.payment_methods
          const idxDefaultMethod = paymentListMethodsRaw.findIndex(element => element.id === result.default_payment_method_id)

          // alterando a ordem dos cartões para exibir o cartão favorito no começo da lista
          const paymentListMethods = changePositionItemInArray(paymentListMethodsRaw, idxDefaultMethod, 0)

          if (paymentListMethods.length < 1) {
            this.listSavedCreditCards = null
            return
          }

          const formattedList = paymentListMethods.map(item => ({
            id: item.id,
            flag: item.data.brand.toLowerCase(),
            name: `${item.data.brand} ${item.data.display_number.slice(15, 19)}`,
            number: item.data.display_number
              .replaceAll('X', '*')
              .replaceAll('-', ' '),
          }))

          this.useNewCard = false
          this.listSavedCreditCards = formattedList
        })
        .catch(error => {
          this.$swal({
            title: 'Ops! Ocorreu um problema.',
            text: `${error.response.data.errors[0]}. Entre em contato com o suporte`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          this.$router.push({ path: '/' })
          return error
        })
    },
    changePaymentMethod() {
      useJwt.setDefaultPaymentMethod(this.selectedCardId)
        .then(() => {
          this.getCompanyPaymentMethods()
        })
        .catch(error => error)
    },
    updateCurrentPlan() {
      useJwt.createPayment({
        planId: this.getIdSelectPlan,
        indications: this.indications,
        value: this.getTotalPrice.newPrice,
        autoRenewal: true,
        tokenCard: '',
        tokenMethodId: this.listSavedCreditCards[0].id,
        set_as_default: '',
        saveCard: false,
      })
        .then(() => {
          this.$router.push({ name: 'company-plan' })
        })
        .catch(error => {
          this.$swal({
            title: 'Ops! Ocorreu um problema.',
            text: 'Não foi possível alterar o plano atual. Tente novamente',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })

          return error
        })
    },
    closeModalAddNewCard() {
      this.$refs['add-new-card-modal'].hide()
      this.$refs['modal-change-payment-method'].hide()
      this.getCompanyPaymentMethods()
    },
  },
}
</script>

<style lang="scss" scope>

  @import '@core/scss/vue/libs/vue-slider.scss';

    .spinbutton-primary {
        button {
            background-color: #6270ff !important; //#7367f0
            color: #fff;
        }
    }

    .box-total {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        /* background-color: #E6E4EB; */
        border-radius: 0 !important;

        h5 {
            margin: 0.1rem 0
        }

        strong {
            font-size: 1.5rem;
            color: #7367f0;
            font-weight: bold;
        }
    }

    .cardFlag {
      min-width: 3em !important;
      margin: 0 2rem 0 0;
    }

    .box-credit-card {
        border: 1px solid #ddd;
        margin: 15px 0 !important;

        .card_flag {
            min-width: 3.5rem !important;
        }

        .card-body {
            padding: 1rem 1.5rem !important;
        }

    }

    .box-flag {
      img {
        max-height: 24px !important;
      }

      svg {
        display: none;
      }
    }

    .border-separate {
      border-left: 2px solid #EBE9F1;
    }

    .checked { border: 1px solid #153BC7; }

    .vue-slider-rail {
      height: 4px !important;
    }
</style>
